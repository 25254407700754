.filter {
    font-size: 1.4rem;
    margin: 0.3rem;
    text-transform: capitalize;
    background-color: transparent;
    color: var(--color-accent);
    opacity: 0.8;
    font-weight: bold;

}

.filter:hover,
.filter.selected {
    opacity: 1;

}

.filter.selected::after {
    content: '';
    display: block;
    margin-top: 0.2rem;
    border: 1px solid var(--color-text);
}

