.todo {
    display: flex;
    justify-content: space-between;
    align-items: center;
    padding: 0.5rem 1rem;
    margin: 0.1rem 0;
    color: var(--color-text)
}

.checkbox {
    width: 1.5rem;
    height: 1.5rem;
}

.text {
    flex: 1 1 0%;
    margin-left: 0.5rem;
    font-size: 1.5rem;
}

.icon {
    width: 26px;
    height: 26px;
    background-color: var(--color-grey);
    border-radius: 100%;
    transition: all 150ms ease-out;
}

.icon:hover {
    color: var(--color-accent);
    transform: rotate(15deg) scale(1.2);
}

.button {
    color: var(--color-text);
    background-color: transparent;
}