.toggle {
    font-size: 1.5rem;
    cursor: pointer;
    background-color: transparent;
    color: var(--color-text);
    transition: all 150ms ease-out;
    
}

.toggle:hover {
    transform: scale(1.1);
    color: var(--color-accent);
}
