.container {
    height: 100%;
    min-height: 0;
    display: flex;
    flex-direction: column;
    background-color: var(--color-bg);
}

.list {
    flex: 1 1 auto;
    overflow-y: auto;
}