.header {
    display: flex;
    justify-content: space-between;
    padding: 0 0.5rem;
    align-items: center;
    background-color: var(--color-bg-dark);
    border-bottom: 1px solid var(--color-grey);

}

.filters {
    display: flex;
}

